(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("PropTypes"), require("antd"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("CascaderSelect", ["React", "PropTypes", "antd", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["CascaderSelect"] = factory(require("React"), require("PropTypes"), require("antd"), require("ReactDOM"));
	else
		root["CascaderSelect"] = factory(root["React"], root["PropTypes"], root["antd"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 