import React from "react";
import ReactDOM from "react-dom";
import { Checkbox, Icon } from "antd";
import { formartOptionsData, formartOneLevelData, findExpendKey, findChildKey } from "../utils";
import "../style/select-layout.scss";

class SelectLayout extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (props.options !== state.options) {
      const deepOptions = JSON.parse(JSON.stringify(props.options));
      const formartOptions = formartOptionsData(deepOptions);
      const levelOneData = formartOneLevelData(formartOptions);
      return { formartOptions, levelOneData };
    } else {
      return { checked: props.checked };
    }
  }
  constructor(props) {
    super(props);
    const deepOptions = JSON.parse(JSON.stringify(this.props.options));
    const formartOptions = formartOptionsData(deepOptions);
    const levelOneData = formartOneLevelData(formartOptions);
    this.state = {
      checked: [],
      selected: [],
      expendKeys: [],
      options: this.props.options,
      formartOptions,
      levelOneData
    };
  }

  selectAction = (e, item) => {
    Utils.stopPropagation(e);
    const { levelOneData } = this.state;
    const keys = findExpendKey(item.parentValue, levelOneData, [item.value]);
    let list = [];
    levelOneData.forEach(v => {
      if (keys.indexOf(v.value) > -1 && v.children && v.children.length > 0) {
        list.push(v.children);
      }
    });
    const expendKeys = keys.filter(v => {
      const item = levelOneData.find(v2 => v2.value === v);
      return Array.isArray(item.children) && item.children.length > 0;
    });
    this.setState({ selected: list, expendKeys });
  };

  checkedAction = (e, item) => {
    Utils.stopPropagation(e);
    const flag = e.target.checked;
    let { levelOneData, checked } = this.state;

    if (flag) {
      const keys = findExpendKey(item.parentValue, levelOneData, [item.value]);
      checked.push(...keys.reverse());
    } else {
      let keys = [item.value];
      if (item.children && item.children.length > 0) {
        keys = findChildKey(item.children, keys);
      }
      checked = checked.filter(v => keys.indexOf(v) === -1);
    }
    checked = [...new Set(checked)];
    this.setState({ checked });
    this.props.onChange && this.props.onChange(checked);
  };

  renderGourp = groups => {
    return groups.map((options, lv) => (
      <ul className="cascader-select-group">
        {options.map(item => (
          <li
            key={item.value}
            className={`${this.state.expendKeys.findIndex(v => v === item.value) > -1 ? "select-expened-item" : ""}`}
            onClick={e => this.selectAction(e, item, lv)}
          >
            <Checkbox
              value={item.value}
              checked={this.state.checked.indexOf(item.value) > -1}
              onChange={e => this.checkedAction(e, item)}
              onClick={e => Utils.stopPropagation(e)}
            />
            <span className="label">{item.label}</span>
            {Array.isArray(item.children) && item.children.length > 0 && <Icon type="right" />}
          </li>
        ))}
      </ul>
    ));
  };
  render() {
    const { selected, formartOptions } = this.state;
    const { position } = this.props;
    const groups = [formartOptions, ...selected];
    return (
      <div
        onClick={e => Utils.stopPropagation(e)}
        style={{ left: position[0], top: position[1] }}
        className="cascader-select-layout"
      >
        {this.renderGourp(groups)}
      </div>
    );
  }
}

class SelectLayoutModal extends React.Component {
  constructor(props) {
    super(props);
    this.ele = document.createElement("div");
  }
  componentDidMount() {
    const { getPopupContainer } = this.props;

    getPopupContainer().appendChild(this.ele);
  }
  componentWillUnmount() {
    const { getPopupContainer } = this.props;
    getPopupContainer().removeChild(this.ele);
    this.ele = null;
  }
  render() {
    const { getPopupContainer, visible, position, ...props } = this.props;
    const { x, y } = getPopupContainer().getBoundingClientRect();
    return ReactDOM.createPortal(
      visible ? <SelectLayout position={[position[0] - x, position[1] - y]} {...props} /> : null,
      this.ele
    );
  }
}
export default SelectLayoutModal;
