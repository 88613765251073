export function formartOptionsData(options) {
  for (let i = 0; i < options.length; i++) {
    const item = options[i];
    if (item.children) {
      item.children.forEach(v => {
        v.parentValue = item.value;
        if (v.children) {
          return formartOptionsData([v]);
        }
      });
    }
  }
  return options;
}

export function formartOneLevelData(options, list = []) {
  for (let i = 0; i < options.length; i++) {
    const item = options[i];
    list.push(item);
    if (item.children) {
      formartOneLevelData(item.children, list);
    }
  }
  return list;
}

export function findExpendKey(key, list = [], keys = []) {
  if (!key) {
    return keys;
  }
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (item.value === key) {
      keys.push(key);
      findExpendKey(item.parentValue, list, keys);
    }
  }
  return keys;
}

export function findChildKey(list = [], keys = []) {
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    keys.push(item.value);
    if (item.children && item.children.length > 0) {
      findChildKey(item.children, keys);
    }
  }
  return keys;
}
