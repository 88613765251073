import React from "react";
import { Icon } from "antd";
import PropsType from "prop-types";
import SelectLayout from "./components/SelectLayout";
import { formartOneLevelData, findChildKey } from "./utils";

class CascaderSelect extends React.Component {
  static propsType = {
    getPopupContainer: PropsType.func,
    options: PropsType.array.isRequired,
    placeholder: PropsType.string,
    disabled: PropsType.bool,
    className: PropsType.string
  };
  static defaultProps = {
    getPopupContainer: () => document.body,
    options: []
  };
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
    this.state = {
      visible: false,
      position: [0, 0],
      checked: []
    };
  }
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ("value" in nextProps) {
      return {
        checked: nextProps.value || []
      };
    }
    return null;
  }
  componentDidMount() {
    document.addEventListener("click", this.hideSelectLayout, false);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.hideSelectLayout, false);
    this.domRef = null;
  }
  showSelectLayout = e => {
    Utils.stopPropagation(e);
    const { x, y, height } = this.domRef.current.getBoundingClientRect();
    this.setState({
      visible: true,
      position: [x, y + height]
    });
  };
  hideSelectLayout = e => {
    const paths = Array.from(e.path);
    const index = paths.findIndex(v => typeof v.className == "string" && v.className.indexOf("cascader-select-layout") > -1);
    const index2 = paths.findIndex(v => typeof v.className == "string" && v.className.indexOf("cutom-cascader-select") > -1);
    if (index === -1 && index2 === -1) {
      this.setState({ visible: false });
    } else {
      !this.state.visible && this.showSelectLayout(e);
    }
  };
  onChange = checked => {
    const levelOneData = formartOneLevelData(this.props.options);
    this.setState({ checked });
    this.props.onChange && this.props.onChange(checked.filter(v => levelOneData.findIndex(v2 => v2.value === v) > -1));
  };

  removeItem = item => {
    let { checked } = this.state;
    let keys = [item.value];
    if (item.children && item.children.length > 0) {
      keys = findChildKey(item.children, keys);
    }
    checked = checked.filter(v => keys.indexOf(v) === -1);
    checked = [...new Set(checked)];
    this.onChange(checked.length > 0 ? checked : null);
  };

  render() {
    const { visible, position, checked } = this.state;
    const { placeholder, getPopupContainer, options, disabled = false, className = "", style } = this.props;
    const levelOneData = formartOneLevelData(options);
    const checkedList = checked.map(v => levelOneData.find(v1 => v1.value === v)).filter(v => !!v);
    return (
      <div
        className={`cutom-cascader-select ant-select ${!disabled ? "ant-select-enabled" : "ant-select-disabled"} ${className}`}
        style={style}
        ref={this.domRef}
      >
        <div className="ant-select-selection ant-select-selection--multiple">
          <div className="ant-select-selection__rendered">
            <div style={{ display: checkedList.length > 0 ? "none" : "block" }} className="ant-select-selection__placeholder">
              {placeholder}
            </div>
            <ul>
              {checkedList.map(v => (
                <li className="ant-select-selection__choice">
                  <div className="ant-select-selection__choice__content">{v.label}</div>
                  <span className="ant-select-selection__choice__remove" onClick={() => this.removeItem(v)}>
                    <Icon type="close" className="ant-select-remove-icon" />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <SelectLayout
          position={position}
          options={options}
          onChange={this.onChange}
          visible={visible}
          checked={checked}
          getPopupContainer={getPopupContainer}
        />
      </div>
    );
  }
}

export default CascaderSelect;
